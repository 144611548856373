// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Table.module.css */

.Table_table__hGi3r {
    width: 100%;
    /* border-collapse: collapse; */
  }
  
  .Table_table__hGi3r th,
  .Table_table__hGi3r td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .Table_table__hGi3r th {
    background-color: #f4f4f4;
  }
  
  .Table_table__hGi3r td input[type="text"] {
    width: 100%; /* Make input fields take up full width */
  }
  
  .Table_table__hGi3r td input[type="number"] {
    width: 90%; /* Make input fields take up full width */
  }
  
  .Table_table__hGi3r td input.Table_textarea__aAKLR {
    width: 100%; /* Make the textarea take up 100% of the horizontal space */
    height: 100px; /* Adjust this value to increase the vertical height */
    box-sizing: border-box; /* Ensure padding and borders are included in the width and height */
    resize:both;
  }


  `, "",{"version":3,"sources":["webpack://./src/Components/Table/Table.module.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;IACI,WAAW;IACX,+BAA+B;EACjC;;EAEA;;IAEE,sBAAsB;IACtB,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,WAAW,EAAE,yCAAyC;EACxD;;EAEA;IACE,UAAU,EAAE,yCAAyC;EACvD;;EAEA;IACE,WAAW,EAAE,2DAA2D;IACxE,aAAa,EAAE,sDAAsD;IACrE,sBAAsB,EAAE,oEAAoE;IAC5F,WAAW;EACb","sourcesContent":["/* Table.module.css */\n\n.table {\n    width: 100%;\n    /* border-collapse: collapse; */\n  }\n  \n  .table th,\n  .table td {\n    border: 1px solid #ddd;\n    padding: 10px;\n    text-align: left;\n  }\n  \n  .table th {\n    background-color: #f4f4f4;\n  }\n  \n  .table td input[type=\"text\"] {\n    width: 100%; /* Make input fields take up full width */\n  }\n  \n  .table td input[type=\"number\"] {\n    width: 90%; /* Make input fields take up full width */\n  }\n  \n  .table td input.textarea {\n    width: 100%; /* Make the textarea take up 100% of the horizontal space */\n    height: 100px; /* Adjust this value to increase the vertical height */\n    box-sizing: border-box; /* Ensure padding and borders are included in the width and height */\n    resize:both;\n  }\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `Table_table__hGi3r`,
	"textarea": `Table_textarea__aAKLR`
};
export default ___CSS_LOADER_EXPORT___;
