import Sample from '../../Components/Approval/ApprovalSample';
import React from 'react'
export default function Purchasemanager() {
    return (
       
    <Sample managerType="Purchase Manager" />

);
}

